(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/common/assets/javascripts/create-team-button.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/common/assets/javascripts/create-team-button.js');

'use strict';

const {
  Link
} = ReactRouterDOM;
const {
  ReactIcon: Icon,
  ReactPaper: Paper
} = svs.ui;
const CreateTeamButton = _ref => {
  let {
    className
  } = _ref;
  const classNames = ['marketplace-create-team-button', 'fc-grey-400'];
  if (className) {
    classNames.push(className);
  }
  const renderButton = () => React.createElement("div", {
    className: classNames.join(' ')
  }, React.createElement(Paper, {
    branding: "sport",
    className: "marketplace-create-team-paper",
    Component: Link,
    to: "/skapa-lag?returnUrl=/lag"
  }, React.createElement("div", {
    className: "margin-bottom-1"
  }, React.createElement(Icon, {
    color: "grey-400",
    icon: "plus",
    size: 300
  })), React.createElement("div", {
    className: "f-medium h4"
  }, React.createElement("span", null, "Skapa ett nytt lag"))));
  return React.createElement(React.Fragment, null, renderButton());
};
setGlobal('svs.marketplace.components.common.CreateTeamButton', CreateTeamButton);

 })(window);