(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/common/assets/javascripts/game-selector.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/common/assets/javascripts/game-selector.js');

'use strict';

const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  getProductBrand
} = svs.components.lbUtils.brandMapping.fn;
const {
  RACING
} = svs.utils.products.productIds;
const {
  ReactBrand: Brand,
  ReactPaper: Paper
} = svs.ui;
const GameSelector = () => {
  const size = useMediaQuery(breakpoints.down(BREAKPOINT_AM)) ? 100 : 150;
  const brands = ['stryktipset', 'europatipset', 'topptipset', 'fulltraff', 'powerplay', 'challenge', 'bomben'];
  const renderBrands = () => brands.map(brandname => React.createElement(Brand, {
    brand: brandname,
    key: brandname,
    size: size,
    square: true
  }));
  const renderRacingBrand = () => {
    const racingBrand = getProductBrand(RACING);
    return React.createElement(Brand, {
      brand: racingBrand,
      key: racingBrand,
      size: size,
      square: true
    });
  };
  return React.createElement("div", {
    className: "marketplace-welcome-wrap"
  }, React.createElement(Paper, {
    branding: "sport"
  }, React.createElement("div", {
    className: "paper-rows"
  }, React.createElement("span", {
    className: "f-600 f-bold"
  }, "V\xE4lj mellan \xE5tta olika spel"), React.createElement("div", {
    className: "marketplace-brands"
  }, renderBrands(), renderRacingBrand()))));
};
setGlobal('svs.marketplace.components.common.GameSelector', GameSelector);

 })(window);