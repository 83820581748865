(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/common/assets/javascripts/team-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/common/assets/javascripts/team-onboarding.js');

'use strict';

const {
  urlMapping
} = svs.core;
const {
  ReactBadge: Badge,
  Button
} = svs.ui;
const {
  CreateTeamButton
} = svs.marketplace.components.common;
const TeamOnboarding = () => {
  const loggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const renderButton = () => React.createElement("div", {
    className: "qa-marketplace-onboarding-btn-holder marketplace-onboarding-btn-holder fc-grey-400"
  }, loggedIn ? React.createElement(CreateTeamButton, null) : React.createElement("a", {
    className: "qa-login-link",
    href: "/logga-in?returnUrl=".concat(urlMapping.get('marketplaceTogether'))
  }, React.createElement(Button, {
    block: true,
    className: "qa-login-button",
    focus: true,
    size: 300
  }, "Logga in")));
  return React.createElement("div", {
    className: "marketplace-welcome-wrap-team"
  }, React.createElement("div", {
    className: "marketplace-onboarding-team-section"
  }, React.createElement("div", {
    className: "margin-bottom"
  }, React.createElement(Badge, null, "1")), React.createElement("div", null, "Skapa ett lag och bjud in alla du k\xE4nner")), React.createElement("div", {
    className: "marketplace-onboarding-team-section"
  }, React.createElement("div", {
    className: "margin-bottom"
  }, React.createElement(Badge, null, "2")), React.createElement("div", null, "L\xE4gg ett spel och k\xF6p andelar")), React.createElement("div", {
    className: "marketplace-onboarding-team-section"
  }, React.createElement("div", {
    className: "margin-bottom"
  }, React.createElement(Badge, null, "3")), React.createElement("div", null, "Dela lika p\xE5 vinsten")), React.createElement("div", {
    className: "marketplace-onboarding-team-section"
  }, renderButton()));
};
setGlobal('svs.marketplace.components.common.TeamOnboarding', TeamOnboarding);

 })(window);