(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/common/assets/javascripts/retail-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/common/assets/javascripts/retail-onboarding.js');

'use strict';

const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM,
  BREAKPOINT_LG
} = svs.components.lbUtils.useMediaQuery;
const {
  ReactIcon: Icon,
  ReactPaper: Paper
} = svs.ui;
const RetailOnboarding = () => {
  let size = 800;
  const isAm = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const isLg = useMediaQuery(breakpoints.down(BREAKPOINT_LG));
  if (isLg) {
    size = 600;
  } else if (isAm) {
    size = 700;
  }
  return React.createElement("div", {
    className: "marketplace-welcome-wrap"
  }, React.createElement(Paper, {
    branding: "sport"
  }, React.createElement("div", {
    className: "paper-contain marketplace-onboarding-box"
  }, React.createElement("div", {
    className: "paper-rows"
  }, React.createElement(Icon, {
    color: "fc-sport",
    icon: "players",
    size: size
  }), React.createElement("div", {
    className: "text"
  }, React.createElement("h3", null, "Alla kan vara med"))))), React.createElement(Paper, {
    branding: "sport"
  }, React.createElement("div", {
    className: "paper-contain marketplace-onboarding-box"
  }, React.createElement("div", {
    className: "paper-rows"
  }, React.createElement(Icon, {
    color: "fc-sport",
    icon: "my-habits",
    size: size
  }), React.createElement("div", {
    className: "text"
  }, React.createElement("h3", null, React.createElement("span", null, "Tillsammans har ni"), ' ', React.createElement("span", null, "st\xF6rre chans att vinna")))))), React.createElement(Paper, {
    branding: "sport"
  }, React.createElement("div", {
    className: "paper-contain marketplace-onboarding-box"
  }, React.createElement("div", {
    className: "paper-rows"
  }, React.createElement(Icon, {
    color: "fc-sport",
    icon: "simplicity",
    size: size
  }), React.createElement("div", {
    className: "text"
  }, React.createElement("h3", null, React.createElement("span", null, "V\xE4lj butik och det"), ' ', React.createElement("span", null, "spel som passar dig")))))));
};
setGlobal('svs.marketplace.components.common.RetailOnboarding', RetailOnboarding);

 })(window);