(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/common/assets/javascripts/dummies.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/common/assets/javascripts/dummies.js');
'use strict';

const {
  ReactButton: Button,
  ReactPaper: Paper
} = svs.ui;
const DummyHero = () => React.createElement("div", {
  className: "marketplace-welcome-image"
}, "[hero here...]");
const DummyArticles = () => {
  const articles = [{
    title: 'Dom har spelet tillsammans  i 35 år',
    ingress: 'På nyårsafton kammade en man från Tranås hem årets sista miljonvinst. Nu ska han fira vinsten med sina barn.'
  }, {
    title: 'Varbergänget vann 1,2 miljoner',
    ingress: 'På nyårsafton kammade en man från Tranås hem årets sista miljonvinst. Nu ska han fira vinsten med sina barn.'
  }];
  return React.createElement("div", {
    className: "dummy-articles"
  }, articles.map(_ref => {
    let {
      title,
      ingress
    } = _ref;
    return React.createElement(Paper, {
      branding: "sport",
      className: "dummy-article",
      key: title
    }, React.createElement("div", {
      className: "dummy-article-image"
    }), React.createElement("div", {
      className: "dummy-article-title"
    }, React.createElement("span", {
      className: "f-800 f-bold"
    }, title)), React.createElement("div", {
      className: "dummy-article-ingress"
    }, React.createElement("span", null, ingress)), React.createElement("div", {
      className: "dummy-article-action"
    }, React.createElement(Button, {
      className: "qa-read-more",
      transparent: true
    }, "L\xE4s mer")));
  }));
};
setGlobal('svs.marketplace.components.common.dummies', {
  DummyArticles,
  DummyHero
});

 })(window);